import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero-all';
import Footer from '../components/Footer';

const RequestPage = () => {
  return (
    <Layout>
      <div>
        <Hero image="/img/wood.jpg" title="let's build &lowbar;." />
      </div>
      <section className="container mx-auto -mt-20">
        <div>
          <iframe
            class="airtable-embed"
            src="https://airtable.com/embed/appwYxfPyVqUGThcN/pagBQAkddyBWWKqPq/form"
            frameborder="0"
            onmousewheel=""
            width="100%"
            height="600px"
          ></iframe>
        </div>
        <Footer />
      </section>
    </Layout>
  );
};

export default RequestPage;
